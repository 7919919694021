@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::before,
*::after {
  box-sizing: border-box;
  margin:0;
  padding:0;
}

html, body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root{
  height: 100%;
  /* background-color: greenyellow; */
}

#App{
  height: 100%;
  /* background-color: lightseagreen; */
  display: flex;
  flex-direction: column;
}

.ant-tabs-content.ant-tabs-content-top{
  /* ant.d Tab Content */
  height: 100%;
}

.btnHighlight {
  background-color: #306cb9;
}
/* #cy {  
   from the demo   
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
} */
/* 
#cy {
  width: 100%;
  height: 82vh;
  border: 1px solid #949494;
  overflow: hidden;
  background-color: #e9e9e9;
} */
.cy {
  width: 100%;
  /* height: 82vh; */
  height: 100%;
  /* position: relative; */
  border: 1px solid #949494;
  overflow: hidden;
  background-color: #e9e9e9;
}

.icon-button{
  /* to center an icon inside a normal button */
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-container.hide-popup{
  display: none;
}


h1 {
  /* opacity: 0.5; */
  font-size: 1em;
  font-weight: bold;
}

.popper-div {
  /* just an example */
  border: 1px solid red;
  background: #fff;
  z-index: 2;
  padding: 0.25em;
  pointer-events: none;
}

#buttons {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  margin: 1em;
}

.popper-handle {
  width: 20px;
  height: 20px;
  background: darkred;
  border-radius: 20px;
  z-index: 2;
}

#btnSimulate {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  margin: 0.2em;
  color: white;
  font-size: large;
  /* background-color: #306cb9; */
}

#ckbFastrun {
  position: absolute;
  right: 5em;
  top: 0;
  z-index: 2;
  margin: 0.2em;
  color: white;
  font-size: large;
  /* background-color: #306cb9; */
}

#rightPaneContainer, #conversionPanel {
  width: 500px;
  background-color: #e9e9e9;
  border: 1px solid #949494;
  padding: 0.5em;
  position: relative;
}

#conversionPanel {
  flex-grow: 1;
  /* width: 70vw; */
}


#rightPaneContainer > #btnCloseSim, #conversionPanel > #btnCloseSim {
  position: absolute;
  top: 0em;
  right: 0em;
  cursor: pointer;
  width: 2.5em;
height: 2.0em;

  /* background-color: #f6f6f6; */
  /* border: 1px solid #949494; */
  /* border-radius: 50%; */

  /* background-color:  rgb(218, 218, 218); */
}

#rightPaneContainer > #btnCloseSim:hover, #conversionPanel > #btnCloseSim:hover {
  background-color: rgb(192, 192, 192);
  /* color:whitesmoke */
}




.simCard {
  margin: 5px 0px;
  min-height: 85px;
  padding: 7px;
  border: 1px solid #949494;
  background-color: #d6d6d6;
}
.simCard.won {
  background-color: rgb(132, 255, 132);
}
.simCard.lost {
  background-color: rgb(255, 180, 180);
}
.simCardProgress {
  width: 100%;
  overflow-wrap: anywhere;
  /* TODO: fix the hight to be max(max-content, somevalue) i guess */
  min-height: 22px;
  background-color: white;
  text-align: center;
  border-radius: 5px;
  border: 1px solid #949494;
  max-height: 6rem;
  overflow-y: auto;
}

.popperInputContainer{
	display: grid;
	width: fit-content;
	grid-template-columns: 1fr 1fr 1fr;
}
.popperInputContainer > input{
  width: 60px;
  border: 1px solid #949494;
}


.toolbarDiv{
  background-color: red;
  border: 1px solid red
}
